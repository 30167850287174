import { NextPage } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { ReactElement } from "react";
import { defaultLanguage, Language } from "../config/LanguageConfiguration";
import { NWONPage } from "../config/NWONPage";
import NWONHeroSection from "../src/components/03-organisms/NWONHeroSection/NWONHeroSection";
import { NWONInitial } from "../src/components/NWONInitial/NWONInitial";
import { isLanguage } from "../src/helpers/general/isLanguage";
import { defaultNameSpaces } from "../src/i18n/i18n-namespaces";
import { GetStaticPropsDefault } from "../src/interfaces/GetStaticPropsDefault";

// pages/404.js
const Custom404: NextPage = (): ReactElement | null => {
  const { t } = useTranslation<"common">();

  return (
    <NWONInitial
      pageInformationData={{
        page: NWONPage.NotFound,
      }}
    >
      <NWONHeroSection
        title={t("404.heroTitle")}
        caption={t("404.heroCaption")}
        subtitle={t("404.heroSubTitle")}
      />
    </NWONInitial>
  );
};

export const getStaticProps: GetStaticPropsDefault = async (context) => {
  const locale = isLanguage(context.locale) ? context.locale : defaultLanguage;

  return {
    props: {
      ...(await serverSideTranslations(locale, defaultNameSpaces, null, [
        Language.German,
        Language.English,
      ])),
    },
  };
};

export default Custom404;
